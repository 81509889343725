import React, { PureComponent } from 'react';

import PlayerContext from './context';

class PlayerProvider extends PureComponent {
  audio = null;
  timeInterval = null;
  practice = null;
  practiceInterval = null;

  state = {
    isAudioPlaying: false,
    isPlayerVisible: false,
    audioTitle: '',
    audioSrc: '',
    duration: 0,
    position: 0,
    progress: 0,

    isPracticePlaying: false,
    practiceDuration: 0,
    practicePosition: 0,
    practiceProgress: 0,
  };

  setAudio = (audioSrc, audioTitle, start) => {
    if (audioSrc === this.state.currentAudioSrc) {
      return;
    }
    if (this.audio) {
      this.audio.pause();
    }
    this.audio = new Audio();
    this.audio.addEventListener('loadedmetadata', (data) => {
      this.setState({ duration: data.path[0].duration });
    });
    this.audio.src = audioSrc;
    if (start) {
      this.play();
      this.setState({
        audioSrc,
        audioTitle,
        isAudioPlaying: true,
        isPlayerVisible: true,
      })
    } else {
      this.setState({
        audioSrc,
        audioTitle,
        isPlayerVisible: true,
      })
    }
  };

  play = () => {
    if (this.state.isPracticePlaying) {
      this.pausePractice();
    }
    if (this.audio) {
      this.audio.play();
    }
    this.timeInterval = setInterval(() => {
      this.updatePosition();
    }, 1000);

    this.setState({
      isAudioPlaying: true,
    });
  };

  pause = () => {
    if (this.audio) {
      this.audio.pause();
    }

    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }

    this.setState({
      isAudioPlaying: false,
    })
  };

  hide = () => {
    if (this.audio) {
      this.audio.pause();
    }

    this.setState({
      isAudioPlaying: false,
      isPlayerVisible: false,
    });
  };

  playPractice = () => {
    if (this.state.isAudioPlaying){
      this.hide();
    }
    if (!this.practice) {
      this.practice = new Audio();
      this.practice.addEventListener('loadedmetadata', (data) => {
        this.setState({ practiceDuration: data.path[0].duration });
      });
      this.practice.src = 'https://mcrcsm-sati.s3.amazonaws.com/afbyw-oneday.mp3';
    }
    this.setState({
      isPracticePlaying: true,
    });
    this.practice.play();
    this.practiceInterval = setInterval(() => {
      this.updatePracticePosition();
    }, 1000);
  };

  pausePractice = () => {
    if (this.practice) {
      this.setState({
        isPracticePlaying: false,
      });
      this.practice.pause();
      if (this.practiceInterval) {
        clearInterval(this.practiceInterval);
      }
    }
  };

  seekPractice = (seekPercent) => {
    if (this.practice) {
      const currentTime = this.state.practiceDuration * seekPercent;
      this.practice.currentTime = currentTime;
      this.setState({
        practicePosition: currentTime,
        practiceProgress: seekPercent,
      })
    }
  };

  updatePosition = () => {
    if (this.audio) {
      let addState = {};
      if (this.audio.ended) {
        addState = {
          isAudioPlaying: false,
          position: 0,
          progress: 0,
        };
      }
      const currentTime = this.audio.currentTime;
      this.setState({
        position: currentTime,
        progress: currentTime / this.state.duration,
        ...addState,
      });
    }
  };

  updatePracticePosition = () => {
    if (this.practice) {
      let addState = {};
      if (this.practice.ended) {
        addState = {
          isPracticePlaying: false,
          // position: 0,
          progress: 100,
        };
      }
      const currentTime = this.practice.currentTime;
      this.setState({
        practicePosition: currentTime,
        practiceProgress: currentTime / this.state.practiceDuration,
        ...addState,
      });
    }
  };

  getCurrentPosition = () => {
    if (this.audio) {
      return this.audio.currentTime;
    } else {
      return 0;
    }
  };

  render() {
    return (
      <PlayerContext.Provider
        value={{
          isAudioPlaying: this.state.isAudioPlaying,
          isPlayerVisible: this.state.isPlayerVisible,
          playAudio: this.play,
          pauseAudio: this.pause,
          hidePlayer: this.hide,
          setAudio: this.setAudio,
          currentAudioTitle: this.state.audioTitle,
          currentAudioSrc: this.state.audioSrc,
          currentAudioDuration: this.state.duration,
          currentAudioPosition: this.state.position,
          getCurrentPosition: this.getCurrentPosition,
          audioProgress: this.state.progress,
          playPractice: this.playPractice,
          pausePractice: this.pausePractice,
          isPracticePlaying: this.state.isPracticePlaying,
          practiceDuration: this.state.practiceDuration,
          practicePosition: this.state.practicePosition,
          practiceProgress: this.state.practiceProgress,
          seekPractice: this.seekPractice,
        }}
      >
        {this.props.children}
      </PlayerContext.Provider>
    );
  }
}

export { PlayerProvider };
